import { SessionService, TokenService } from '@jsmodules/core';
import di from '@jsmodules/di';
import { AccountApi } from '@shared/api/dist/generated/v1/account';

export function setupAuth() {
    console.log("setup auth api");

    //检查Token是否过期，是否需要换取新的token
    TokenService.interceptors.getTokenObject.use(async (_i, token) => {
        if (!token) {
            return null;
        }
        if (token.auto_login) {
            const expires_unix = token.created_unix + token.expires;
            const now_unix = Math.floor(new Date().getTime());
            if (expires_unix <= now_unix) {
                return null;
            }
            const refresh_unix = expires_unix - token.expires / 5;
            if (refresh_unix <= now_unix) {
                return null;
            }
        }
        return token;
    });
    //设置如果获取安全头
    TokenService.interceptors.getSecurityHeaders.use(async (obj) => {
        if (obj) {
            return { "auth-ticket": obj.access_token };
        }
        return null;
    });
    //添加用户名密码登录方式
    TokenService.interceptors.loginMethods.use("password", async (options) => {
        const { username, password } = options.data;
        if (!username || !password) {
            throw new Error("用户名和密码不能为空");
        }
        const api = di.getInstance(AccountApi);
        const res = await api.login().post({
            userName: username,
            password,
        });
        const user = res.data;
        const authTicket = res.headers["auth-ticket"];

        return {
            auto_login: options.auto_login,
            key: `t-${new Date().getTime()}`,
            user,
            access_token: authTicket,
        };
    });
    //添加电话登录方式
    TokenService.interceptors.loginMethods.use("phone", async (options) => {
        const { phoneNumber, verifyCode, verifyCodeSig, captcha, captchaSig, isConsole } = options.data;
        if (!phoneNumber || !verifyCode) {
            throw new Error("电话号码和验证码不能为空");
        }
        const api = di.getInstance(AccountApi);
        const res = await api.login().post({
            phone: phoneNumber,
            verifyCode,
            verifyCodeSig,
            captcha,
            captchaSig,
            isConsole
        });
        const user = res.data;
        const authTicket = res.headers["auth-ticket"];
        return {
            auto_login: options.auto_login,
            key: `t-${new Date().getTime()}`,
            user,
            access_token: authTicket,
        };
    });

    SessionService.interceptors.getUser.use(async () => {
        const tokenService = di.getInstance(TokenService);
        const obj = await tokenService.getTokenObject();
        return obj?.user || null;
    });
}
