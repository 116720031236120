"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URLSearchParams = exports.URLSearchParamsImpl = void 0;
var tslib_1 = require("tslib");
var serializeParam = function (value) {
    return encodeURIComponent(value).replace(/%20/g, "+");
};
var deserializeParam = function (value) {
    return decodeURIComponent(String(value).replace(/\+/g, " "));
};
var URLSearchParamsImpl = /** @class */ (function () {
    function URLSearchParamsImpl(searchString) {
        this.polyfill = true;
        this._entries = new Map();
        var ssType = typeof searchString;
        if (!searchString) {
        }
        else if (searchString instanceof URLSearchParamsImpl) {
            searchString.forEach(function (value, name) {
                this.append(name, value);
            });
        }
        else if (ssType === "string") {
            this._fromString(searchString);
        }
        else if (ssType === "object") {
            if (Array.isArray(searchString)) {
                for (var i = 0; i < searchString.length; i++) {
                    var entry = searchString[i];
                    if (Array.isArray(entry) || entry.length !== 2) {
                        this.append(entry[0], entry[1]);
                    }
                    else {
                        throw new TypeError("Expected [string, any] as entry at index " + i + " of URLSearchParams's input");
                    }
                }
            }
            else {
                for (var key in searchString) {
                    if (searchString.hasOwnProperty(key)) {
                        this.append(key, searchString[key]);
                    }
                }
            }
        }
        else {
            throw new TypeError("Unsupported input's type for URLSearchParams");
        }
    }
    URLSearchParamsImpl.prototype.append = function (name, value) {
        if (this.has(name)) {
            this._entries.get(name).push(String(value));
        }
        else {
            this._entries.set(name, [String(value)]);
        }
    };
    URLSearchParamsImpl.prototype.delete = function (name) {
        this._entries.delete(name);
    };
    URLSearchParamsImpl.prototype.get = function (name) {
        if (this.has(name)) {
            return this._entries.get(name)[0];
        }
        return null;
    };
    URLSearchParamsImpl.prototype.getAll = function (name) {
        if (this.has(name)) {
            return tslib_1.__spread(this._entries.get(name));
        }
        return null;
    };
    URLSearchParamsImpl.prototype.set = function (name, value) {
        this._entries.set(name, [String(value)]);
    };
    URLSearchParamsImpl.prototype.has = function (name) {
        return this._entries.has(name);
    };
    URLSearchParamsImpl.prototype.forEach = function (callback, thisArg) {
        var _this = this;
        this._entries.forEach(function (value, key) {
            callback.call(thisArg, value, key, _this);
        });
    };
    URLSearchParamsImpl.prototype.keys = function () {
        return this._entries.keys();
    };
    URLSearchParamsImpl.prototype.values = function () {
        return this._entries.values();
    };
    URLSearchParamsImpl.prototype.entries = function () {
        return this._entries.entries();
    };
    URLSearchParamsImpl.prototype.toString = function () {
        var searchArray = [];
        this.forEach(function (value, name) {
            var e_1, _a;
            var key = serializeParam(name);
            try {
                for (var value_1 = tslib_1.__values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var val = value_1_1.value;
                    searchArray.push(key + "=" + serializeParam(val));
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_a = value_1.return)) _a.call(value_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
        return searchArray.join("&");
    };
    URLSearchParamsImpl.prototype._fromString = function (searchString) {
        if (this._entries.size > 0) {
            this._entries.clear();
        }
        searchString = searchString.replace(/^\?/, "");
        if (!searchString) {
            return;
        }
        var attributes = searchString.split("&");
        var attribute;
        for (var i = 0; i < attributes.length; i++) {
            attribute = attributes[i].split("=");
            this.append(deserializeParam(attribute[0]), attribute.length > 1 ? deserializeParam(attribute[1]) : "");
        }
    };
    URLSearchParamsImpl.prototype.sort = function () { };
    return URLSearchParamsImpl;
}());
exports.URLSearchParamsImpl = URLSearchParamsImpl;
var $scope = typeof global !== "undefined" ? global : typeof window !== "undefined" ? window : this;
var NativeURLSearchParams = (function () {
    try {
        if ($scope.URLSearchParams) {
            var params = new $scope.URLSearchParams({ foo: "bar" });
            var test_1 = true;
            if (params.get("foo") !== "bar") {
                test_1 = false;
            }
            if (params.toString() !== "foo=bar") {
                test_1 = false;
            }
            if (test_1) {
                return $scope.URLSearchParams;
            }
        }
        return URLSearchParamsImpl;
    }
    catch (e) { }
    return URLSearchParamsImpl;
})();
exports.URLSearchParams = NativeURLSearchParams;
