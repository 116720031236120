"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RxDbConnection = void 0;
var tslib_1 = require("tslib");
var rxdb_1 = require("rxdb");
var RxDbDbConnectionImpl = /** @class */ (function () {
    function RxDbDbConnectionImpl() {
        this.create_promises = new Map();
        this.manager = new Map();
    }
    RxDbDbConnectionImpl.prototype.createDatabase = function (name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var setting, config, collections, database;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.manager.has("rxdbconnection_" + name)) {
                            setting = this.manager.get("rxdbconnection_" + name);
                        }
                        else if (this.manager.has("rxdb_factory")) {
                            setting = this.manager.get("rxdb_factory")(name);
                            if (setting) {
                                this.manager.set("rxdbconnection_" + name, setting);
                            }
                        }
                        if (!setting) {
                            throw new Error("RxDB: \u672A\u627E\u5230\u6570\u636E\u5E93\u8FDE\u63A5\u914D\u7F6E," + name);
                        }
                        config = setting.config, collections = setting.collections;
                        return [4 /*yield*/, rxdb_1.createRxDatabase(config)];
                    case 1:
                        database = _a.sent();
                        console.log("DatabaseService: created database");
                        // show leadership in title
                        database.waitForLeadership().then(function () {
                            console.log("isLeader now");
                        });
                        // create collections
                        console.log("DatabaseService: create collections");
                        return [4 /*yield*/, Promise.all(collections.map(function (colData) {
                                console.log("create collection", colData.name);
                                return database.collection(colData);
                            }))];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, database];
                }
            });
        });
    };
    RxDbDbConnectionImpl.prototype.addFactory = function (factory) {
        this.manager.set("rxdb_factory", factory);
        return this;
    };
    RxDbDbConnectionImpl.prototype.addConfig = function (dbName, setting) {
        this.manager.set("rxdbconnection_" + dbName, setting);
        return this;
    };
    RxDbDbConnectionImpl.prototype.get = function (name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, db;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = "rxdbconnection_" + name;
                        if (!!this.create_promises.has(key)) return [3 /*break*/, 1];
                        this.create_promises.set(key, this.createDatabase(name));
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.create_promises.get(key)];
                    case 2:
                        db = _a.sent();
                        if (db.destroyed) {
                            this.create_promises.set(key, this.createDatabase(name));
                        }
                        else {
                            return [2 /*return*/, db];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/, this.create_promises.get(key)];
                }
            });
        });
    };
    /**
     * 销毁数据库实例
     * @param name
     */
    RxDbDbConnectionImpl.prototype.destroy = function (name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var key, db;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        key = "rxdbconnection_" + name;
                        if (!this.create_promises.has(key)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.get(name)];
                    case 1:
                        db = _a.sent();
                        return [4 /*yield*/, db.destroy()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 删除数据库
     * @param name
     */
    RxDbDbConnectionImpl.prototype.remove = function (name) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var db, key;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.get(name)];
                    case 1:
                        db = _a.sent();
                        return [4 /*yield*/, db.remove()];
                    case 2:
                        _a.sent();
                        key = "rxdbconnection_" + name;
                        if (this.create_promises.has(key)) {
                            this.create_promises.delete(key);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return RxDbDbConnectionImpl;
}());
exports.RxDbConnection = new RxDbDbConnectionImpl();
