import { Card, DatePicker, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import RequestLoader from '@/components/RequestLoader';
import { DualAxes } from '@ant-design/charts';
import { useResolveClass } from '@jsmodules/react';
import StatisticApi from '@shared/api/dist/generated/v1/statistic';

interface IProps {}

function toMap(items) {
	console.log(items);
    let data = {};
    for (const item of items) {
        data[item.time] = item.value;
    }
    return data;
}

export const Chart1 = (props: IProps) => {
    const [selectedKey, setSelectedKey] = useState("payamount");
    const [dates, setDates] = useState<any>([moment().add(-30, "d"), moment()]);

    const [data, setData] = useState<any>([[], []]);
    const [loading, setLoading] = useState(true);
    const api = useResolveClass(StatisticApi);

    useEffect(() => {
        setLoading(true);
        RequestLoader.run(
            async () => {
                const [startDate, endDate] = dates;
                const bDate = dates[0].format("YYYY-MM-DD");
                const eDate = dates[1].format("YYYY-MM-DD");
                const [res1, res2, res3, res4] = await Promise.all([
                    api
                        .payamount()
                        .get({
                            bDate,
                            eDate,
                        })
                        .then((res) => {
                            return toMap(res.data);
                        }),
                    api
                        .unpayamount()
                        .get({
                            bDate,
                            eDate,
                        })
                        .then((res) => {
                            return toMap(res.data);
                        }),
                    api
                        .ordercount()
                        .get({
                            bDate,
                            eDate,
                        })
                        .then((res) => {
                            return toMap(res.data);
                        }),
					api
                        .accountcountbyday()
                        .get({
                            bDate,
                            eDate,
                        })
                        .then((res) => {
                            return toMap(res.data);
                        }),
                ]);


                const days = endDate.diff(startDate, "d") + 1;
                let columnData: any = [];
                let lineData: any = [];
                for (let i = 0; i < days; i++) {
                    const date = startDate.clone().add(i, "d").format("YYYY-MM-DD");
                    columnData.push(
                        {
                            time: date,
                            value: res1[date] || 0,
                            type: "支付金额",
                        },
                        {
                            time: date,
                            type: "待支付金额",
                            value: res2[date] || 0,
                        }
                    );
                    lineData.push(
						{
							time: date,
							type: "订单数量",
							count: res3[date] || 0,
                    	},
						{
							time: date,
							type: "新增注册",
							count: res4[date] || 0,
                    	}
					);
                }
                setLoading(false);
                setData([columnData, lineData]);
            },
            {
                hideToast: true,
            }
        ).catch((err) => {
            setLoading(false);
        });
        //eslint-disable-next-line
    }, [dates]);

    var config = {
        data: data,
        xField: "time",
        yField: ["value", "count"],
        geometryOptions: [
            {
                geometry: "column",
                isStack: true,
                seriesField: "type",
                tooltip: {
                    formatter: (item) => {
                        return { name: item.type, value: "￥ " + (item.value / 100).toFixed(2) };
                    },
                },
            },
            {
                geometry: "line",
                seriesField: "type",
                tooltip: {
                    formatter: (item) => {
                        return { name: item.type, value: `${item.count}` };
                    },
                },
            },
        ],
    };
    const disabledDate = (current) => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
        const tooEarly = dates[1] && dates[1].diff(current, "days") > 30;
        return tooEarly || tooLate;
    };
    return (
        <Card
            activeTabKey={selectedKey}
            onTabChange={setSelectedKey}
            tabList={[{ tab: "商城实时数据概览", key: "payamount" }]}
            tabBarExtraContent={
                <DatePicker.RangePicker
                    allowClear={false}
                    // disabledDate={disabledDate}
                    ranges={{
                        近3天: [moment().add(-2, "d"), moment()],
                        近7天: [moment().add(-6, "d"), moment()],
                        近30天: [moment().add(-29, "d"), moment()],
                    }}
                    value={dates}
                    onChange={(vals) => {
                        setDates(vals);
                    }}
                />
            }
        >
            <Spin spinning={loading}>
                <DualAxes {...config} />
            </Spin>
        </Card>
    );
};

export default Chart1;
