"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiHosts = void 0;
var tslib_1 = require("tslib");
var _apiHosts = {
    api_v1: "https://api.wocun9.com",
};
if (API_ENV === "dev") {
    _apiHosts.api_v1 = "http://wocun.eeeban.com";
}
if (__DEV__) {
    _apiHosts = tslib_1.__assign(tslib_1.__assign({}, _apiHosts), { api_v1: "/proxy-api" });
}
exports.apiHosts = _apiHosts;
