import classNames from 'classnames';
import * as React from 'react';

import css from './index.module.less';

export default function PageContent(props: React.HTMLAttributes<HTMLDivElement>) {
    const { className, children, ...otherProps } = props;
    return (
        <div className={classNames(css.pageContent, className)} {...otherProps}>
            {children}
        </div>
    );
}
