import { Input, Spin } from 'antd';
import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';

export function setupAntd() {
    Spin.defaultProps["indicator"] = <LoadingOutlined style={{ fontSize: "28px" }} />;

    Input.defaultProps["autoComplete"] = "off";
}
