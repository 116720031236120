"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Task = void 0;
var tasksMap = new Map();
exports.Task = {
    /**
     * 多次调用只执行一次，执行成功后不会删除缓存结果
     * @param key
     * @param func
     */
    onceAsync: function (key, func) {
        if (!tasksMap.has(key)) {
            tasksMap.set(key, func());
        }
        return tasksMap
            .get(key)
            .then(function (result) {
            return result;
        })
            .catch(function (err) {
            tasksMap.delete(key);
            return Promise.reject(err);
        });
    },
    /**
     * 同时调用执行一次，执行成功后会删除缓存结果
     * @param key
     * @param func
     */
    throttleAsync: function (key, func) {
        return exports.Task.onceAsync(key, func).then(function (result) {
            tasksMap.delete(key);
            return result;
        });
    },
};
