import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import React from 'react';

import ImageMogr2 from '@jsmodules/image-mogr2';
import { CDNHosts } from '@shared/config';

interface IAutoAvatarProps extends AvatarProps {
    src?: string;
    avatarSize?: number;
}

export function AutoAvatar(props: IAutoAvatarProps) {
    const { src, ...avatarProps } = props;
    let sourceURL = "";
    if (src) {
        const avatarSize = props.avatarSize || 132;
        sourceURL = ImageMogr2.src(src, CDNHosts.image, [CDNHosts.image])
            .thumbnail({ width: avatarSize, height: avatarSize })
            .render();
    }
    return <Avatar src={sourceURL} icon="user" {...avatarProps} />;
}

export default AutoAvatar;
