"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Application = void 0;
var tslib_1 = require("tslib");
function createApp() {
    var setups = [];
    return {
        use: function (callback) {
            setups.push(callback);
            return exports.Application;
        },
        initAsync: function () {
            return tslib_1.__awaiter(this, void 0, void 0, function () {
                var initialState, setups_1, setups_1_1, setup, state, e_1_1;
                var e_1, _a;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            initialState = {};
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 6, 7, 8]);
                            setups_1 = tslib_1.__values(setups), setups_1_1 = setups_1.next();
                            _b.label = 2;
                        case 2:
                            if (!!setups_1_1.done) return [3 /*break*/, 5];
                            setup = setups_1_1.value;
                            return [4 /*yield*/, setup(initialState)];
                        case 3:
                            state = _b.sent();
                            initialState = tslib_1.__assign(tslib_1.__assign({}, initialState), state);
                            _b.label = 4;
                        case 4:
                            setups_1_1 = setups_1.next();
                            return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 8];
                        case 6:
                            e_1_1 = _b.sent();
                            e_1 = { error: e_1_1 };
                            return [3 /*break*/, 8];
                        case 7:
                            try {
                                if (setups_1_1 && !setups_1_1.done && (_a = setups_1.return)) _a.call(setups_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/, initialState];
                    }
                });
            });
        },
    };
}
exports.Application = createApp();
