"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URL = void 0;
var URLSearchParams_1 = require("./URLSearchParams");
function removeUsername(match, username, password) {
    if (password === "@") {
        return "";
    }
    else {
        return password;
    }
}
var URLImpl = /** @class */ (function () {
    function URLImpl(url, base) {
        this.url = url;
        this.base = base;
        this.polyfill = true;
        this.searchParams = new URLSearchParams_1.URLSearchParamsImpl();
        if (typeof url === "string") {
            var urlIsValid = /^[a-zA-z]+:\/\/.*/.test(url);
            var baseIsValid = /^[a-zA-z]+:\/\/.*/.test(base);
            if (urlIsValid) {
                this.href = url;
            }
            else if (baseIsValid) {
                this.href = base.replace(/\/$/, "") + "/" + url.replace(/^\//, "");
            }
            else {
                throw new TypeError('URL string is not valid. If using a relative url, a second argument needs to be passed representing the base URL. Example: new URL("relative/path", "http://www.example.com");');
            }
        }
        else if (url instanceof URLImpl) {
            // Copy all of the location or link properties to the
            // new URL instance.
            // this._hash = url.hash;
            // this._hostname = url.hostname;
            // this._password = url.password ? url.password : "";
            // this._pathname = url.pathname;
            // this._port = url.port;
            // this._protocol = url.protocol;
            // this._username = url.username ? url.username : "";
            this.href = url.href;
        }
    }
    Object.defineProperty(URLImpl.prototype, "hash", {
        get: function () {
            return this._hash;
        },
        set: function (value) {
            this._hash = value.length > 0 ? "#" + value.match(/^#*(.*)/)[1] : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "host", {
        get: function () {
            return this._port.length > 0 ? this._hostname + ":" + this._port : this._hostname;
        },
        set: function (value) {
            var parts = value.split(":");
            this.hostname = parts[0];
            this.port = parts[1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "hostname", {
        get: function () {
            return this._hostname;
        },
        set: function (value) {
            this._hostname = value.length > 0 ? encodeURIComponent(value) : this._hostname;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "href", {
        get: function () {
            var hrefStr = this.protocol + "//";
            if (this.username.length > 0 || this.password.length > 0) {
                if (this.username.length > 0) {
                    hrefStr += this.username;
                }
                if (this._password.length > 0) {
                    hrefStr += ":" + this.password;
                }
                hrefStr += "@";
            }
            hrefStr += this.hostname;
            if (this.port.length > 0) {
                hrefStr += ":" + this.port;
            }
            hrefStr += this.pathname + this.search + this.hash;
            return hrefStr;
        },
        set: function (value) {
            this.protocol = value;
            value = value.replace(/.*?:\/*/, "");
            var usernameMatch = value.match(/([^:]*).*@/);
            this.username = usernameMatch ? usernameMatch[1] : "";
            value = value.replace(/([^:]*):?(.*@)/, removeUsername);
            var passwordMatch = value.match(/.*(?=@)/);
            this.password = passwordMatch ? passwordMatch[0] : "";
            value = value.replace(/.*@/, "");
            this.hostname = value.match(/[^:/?]*/);
            var portMatch = value.match(/:(\d+)/);
            this.port = portMatch ? portMatch[1] : "";
            var pathnameMatch = value.match(/\/([^?#]*)/);
            this.pathname = pathnameMatch ? pathnameMatch[1] : "";
            var searchMatch = value.match(/\?[^#]*/);
            this.search = searchMatch ? searchMatch[0] : "";
            var hashMatch = value.match(/\#.*/);
            this.hash = hashMatch ? hashMatch[0] : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "origin", {
        get: function () {
            var originStr = this._protocol + "//" + this._hostname;
            if (this._port.length > 0) {
                originStr += ":" + this._port;
            }
            return originStr;
        },
        set: function (value) {
            this.protocol = value;
            value = value.replace(/.*?:\/*/, "");
            this.hostname = value.match(/[^:/?]*/);
            var portMatch = value.match(/:(\d+)/);
            this.port = portMatch ? portMatch[1] : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "password", {
        get: function () {
            return this._password;
        },
        set: function (value) {
            this._password = encodeURIComponent(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "pathname", {
        get: function () {
            return this._pathname;
        },
        set: function (value) {
            this._pathname = "/" + value.match(/\/?(.*)/)[1];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "port", {
        get: function () {
            return this._port;
        },
        set: function (value) {
            if (isNaN(value) || value === "") {
                this._port = "";
            }
            else {
                this._port = Math.min(65535, value).toString();
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "protocol", {
        get: function () {
            return this._protocol;
        },
        set: function (value) {
            this._protocol = value.match(/[^/:]*/)[0] + ":";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "search", {
        get: function () {
            return "?" + this.searchParams.toString();
        },
        set: function (value) {
            this.searchParams._fromString(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(URLImpl.prototype, "username", {
        get: function () {
            return this._username;
        },
        set: function (v) {
            this._username = v;
        },
        enumerable: false,
        configurable: true
    });
    return URLImpl;
}());
var $scope = typeof global !== "undefined" ? global : typeof window !== "undefined" ? window : this;
var NativeURL = (function () {
    try {
        if ($scope.URL) {
            var url = new $scope.URL("/foo?foo=bar");
            if (url.searchParams && url.searchParams.get("foo") === "bar") {
                return $scope.URL;
            }
        }
        return URLImpl;
    }
    catch (e) { }
    return URLImpl;
})();
exports.URL = NativeURL;
