import React from 'react';

import { LockOutlined, LaptopOutlined, DollarCircleOutlined, BellOutlined, AccountBookOutlined, BankOutlined, CreditCardOutlined, DashboardOutlined, FileImageOutlined, SettingOutlined, TransactionOutlined, UnorderedListOutlined, UserOutlined, ClearOutlined, CarOutlined } from '@ant-design/icons';


export const menusList = [
    {
        key: "dashboard",
        icon: <DashboardOutlined />,
        title: "概览",
        path: "/dashboard",
		meta: {
			scopes: 'Summary'
		}
    },
    {
        key: "products",
        icon: <UnorderedListOutlined />,
        title: "商品管理",
        menus: [
            {
                key: "list",
                title: "零售商品",
                path: "/products/list?isMembership=%3D%21false",
            },
            {
                key: 'memberList',
                title: "会员卡商品",
                path: "/products/memberList?isMembership=%3D%21true",
            },
            {
                key: "category",
                title: "分类管理",
                path: "/products/category",
            },
        ],
		meta: {
			scopes: 'ProductManage'
		}
    },
    {
        key: "membersystem",
        icon: <CreditCardOutlined />,
        title: "会员体系",
        menus: [
            {
                key: "list",
                title: "会员体系",
                path: "/membersystem/list",
            },
        ],
		meta: {
			scopes: 'MemberSystem'
		}
    },
    {
        key: "material",
        icon: <FileImageOutlined />,
        title: "素材管理",
        menus: [
            {
                key: "files",
                title: "所有素材",
                path: "/material/files",
            },
        ],
		meta: {
			scopes: 'MaterialManage'
		}
    },
    {
        key: "transaction",
        icon: <TransactionOutlined />,
        title: "交易管理",
        menus: [
            {
                key: "orders",
                title: "商品订单",
                path: "/transaction/orders/default?extraData.type=%3Dproduct",
            },
            {
                key: "membersorders",
                title: "会员卡订单",
                path: "/transaction/membersorders/default?extraData.type=%3Dmember",
            },
            {
                key: "membersuporders",
                title: "会员卡升级订单",
                path: "/transaction/membersuporders/member?extraData.type=%3DmemberUpgrade",
            },
        ],
		meta: {
			scopes: 'TransactionManage'
		}
    },
    {
        key: 'capital',
        icon: <DollarCircleOutlined />,
        title: '资金管理',
        menus: [
            {
                key: "capitalList",
                title: "资金管理",
                path: "/capital/capitalList",
            },
            {
                key: "withdraw",
                title: "提现申请",
                path: "/capital/withdraw",
            }
        ],
		meta: {
			scopes: 'FundManage'
		}
    },
    {
        key: "logistics",
        icon: <CarOutlined />,
        title: "物流管理",
        menus: [
            {
                key: 'orderLogistics',
                title: '订单物流',
                path: "/logistics/orderLogistics?status=%3DPaid"
            },
            {
                key: 'takeLogistics',
                title: '提货物流',
                path: '/logistics/takeLogistics?status=%3DCreated'
            }
        ],
		meta: {
			scopes: 'LogisticManage'
		}
    },
    {
        key: "storage",
        icon: <BankOutlined />,
        title: "存酒管理",
        menus: [
            {
                key: "storageitem",
                title: "存酒列表",
                path: "/storage/storageitem",
            },
            {
                key: "storagetake",
                title: "提货申请",
                path: "/storage/storagetake",
            },
        ],
		meta: {
			scopes: 'StorageManage'
		}
    },
    {
        key: "users",
        icon: <UserOutlined />,
        title: "人员管理",
        menus: [
            // {
            //     key: "saleMembers",
            //     title: "销售管理",
            //     path: "/users/saleMembers?role=%3DSeller",
            // },
            {
                key: "members",
                title: "会员管理",
                path: "/users/members",
            },
            {
                key: "agentApply",
                title: "代理申请",
                path: "/users/agentApply?isApproval=%3Dnull",
            },
        ],
		meta: {
			scopes: 'MemberManage'
		}
    },
    {
        key: "articles",
        icon: <UnorderedListOutlined />,
        title: "文章管理",
        menus: [
            {
                key: "list",
                title: "文章管理",
                path: "/articles/list",
            },
            {
                key: "category",
                title: "分类管理",
                path: "/articles/category",
            },
        ],
		meta: {
			scopes: 'ArticleManage'
		}
    },
    {
        key: "news",
        icon: <BellOutlined />,
        title: "消息管理",
        menus: [
            {
                key: 'new',
                title: '消息管理',
                path: '/news/new'
            },
            // {
            //     key: 'proposal',
            //     title: "投诉建议",
            //     path: '/news/proposal'
            // }
        ],
		meta: {
			scopes: 'NotificationManage'
		}
    },
    {
        key: "marketing",
        icon: <AccountBookOutlined />,
        title: "营销活动",
        menus: [
            {
                key: "coupon",
                title: "优惠券",
                path: "/marketing/coupon",
            },
        ],
		meta: {
			scopes: 'PromotionManage'
		}
    },
    {
        key: "system",
        icon: <SettingOutlined />,
        title: "系统管理",
        menus: [
            {
                key: "accounts",
                title: "账号管理",
                path: "/system/accounts",
            },
            {
                key: "setting",
                title: "系统设置",
                path: "/system/setting",
            },
        ],
		meta: {
			scopes: 'SystemManage'
		}
    },
    // {   key: "permission",
    //     icon: <LockOutlined />,
    //     title: "权限管理",
    //     path: '/permission'
    // },
    {   key: "operationlog",
        icon: <LaptopOutlined />,
        title: "操作日志",
        path: '/operationlog',
		meta: {
			scopes: 'Log'
		}
    },
    {
        key: "recycle",
        icon: <ClearOutlined />,
        title: "回收站",
        path: "/recycle",
		meta: {
			scopes: 'Recyclebin'
		}
    }
];
