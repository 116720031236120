import './index.less';

import { Button, PageHeader, Result, Spin } from 'antd';
import * as React from 'react';

import { idgenerator } from '@jsmodules/core';

import PageContent from '../Page/Content';

const cachedComponents = {};

type AsyncComponentStatus = "loading" | "success" | "fail";

/**
 * 异步请求组件
 */
export function AsyncComponent(importComponent) {
    const cachedKey = `lazy-${idgenerator.guid()}`;
    return function AsyncComponent(props) {
        let CachedComponent = cachedComponents[cachedKey];
        const [status, setStatus] = React.useState<AsyncComponentStatus>(() => {
            let initialStatus: AsyncComponentStatus = !!CachedComponent ? "success" : "loading";
            return initialStatus;
        });

        const retry = () => {
            if (status !== "success") {
                importComponent()
                    .then((module) => {
                        cachedComponents[cachedKey] = module.default || module;
                        setStatus("success");
                    })
                    .catch(() => {
                        setStatus("fail");
                    });
            }
        };

        React.useEffect(() => {
            retry();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (status === "loading") {
            return (
                <>
                    <PageHeader title="&nbsp;" ghost={false} />
                    <PageContent>
                        <Spin delay={200}>
                            <div className="async-loading"></div>
                        </Spin>
                    </PageContent>
                </>
            );
        }
        if (status === "fail") {
            return (
                <>
                    <PageHeader title="错误" ghost={false}></PageHeader>
                    <PageContent>
                        <Result
                            status={500}
                            title="Oops!"
                            subTitle="页面加载失败,请刷新重试."
                            extra={
                                <Button onClick={retry} type="primary">
                                    立即重试
                                </Button>
                            }
                        />
                    </PageContent>
                </>
            );
        }
        return <CachedComponent {...props} />;
    };
}

export default AsyncComponent;
