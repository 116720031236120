"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.CDNHosts = void 0;
exports.CDNHosts = {
    image: "//wocun-1304070890.file.myqcloud.com",
    file: "//wocun-1304070890.file.myqcloud.com",
};
if (API_ENV === "dev") {
    exports.CDNHosts.image = "//wocun-1300150819.piccd.myqcloud.com";
    exports.CDNHosts.file = "//wocun-1300150819.file.myqcloud.com";
}
