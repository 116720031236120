import 'antd/dist/antd.less';

// import 'antd/dist/antd.compact.less';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React from 'react';

import { AccessProvider, AppProvider } from '@jsmodules/react';

import { accessFactory } from './access';
import AntdDialogHost from './components/AntdDialogHost';
import SplashScreen from './components/SplashScreen';
import { getInitialState } from './initialState';
import { AppRoutes } from './routers';

function App() {
    return (
        <ConfigProvider locale={zhCN} pageHeader={{ ghost: false }}>
            <AppProvider getInitialState={getInitialState}>
                <SplashScreen>
                    <AccessProvider accessFactory={accessFactory}>
                        <AppRoutes />
                        <AntdDialogHost />
                    </AccessProvider>
                </SplashScreen>
            </AppProvider>
        </ConfigProvider>
    );
}

export default App;
