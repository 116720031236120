import React, { useEffect, useState, useCallback } from 'react';
import { Button, Statistic, Card, Row, Col  } from 'antd';
import styles from './index.module.less';

let tabList = [
    {name: '全部', type: 1, check: true},
    {name: '7天', type: 1, check: false},
    {name: '30天', type: 1, check: false},
    {name: '90天', type: 1, check: false},
]

// let tabIndex = 0;
interface IProps{
    numList :any,
    title :String,
    dateChange :any,
    getType :any,
    listIndex :Number
}

export function StatisticsModel(props :IProps){
    const {numList, title, dateChange, getType, listIndex} = props;
    const [tabIndex, setTabIndex] = React.useState(0);
    const changetab = (index) => {
        setTabIndex(index);
        // getType(index, listIndex);
        handTab(index);
    }

    const handTab = useCallback((index)=>{
        console.log(index)
        getType(index)
    },[listIndex, getType])


    return (
        <>
        <div className={styles.cardBox}>
            <div className={styles.titleBox}> 
                <div className={styles.title}>{title}</div>
                {dateChange && (
                    <div className={styles.tabBox}>
                        {
                            tabList.map((item, index)=>{
                                return (
                                    <div 
                                        style={{color: index == tabIndex?'#F54':''}}
                                        onClick={()=>changetab(index)}
                                        className={styles.tabList}
                                        key={index}>
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
            </div>
            {
                numList.map((item, index)=>{
                    return (
                        <Row key={index} gutter={16}>
                            {
                                item.map((list, dataIndex) => {
                                    return (
                                        <Col key={dataIndex} span={6}>
                                            <Card>
                                            <Statistic
                                                title={list.title}
                                                value={list.value}
                                                precision={list.precision}
                                                valueStyle={{ color: '#3f8600' }}
                                                suffix={list.suffix}
                                            />
                                            {list.hasBtn && (
                                                <Button style={{ marginTop: 16 }} type="primary">
                                                    {list.btnText}
                                                </Button>
                                            )}
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    )
                })
            }
        </div>
        </>
    )
}

export default StatisticsModel;