"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppProvider = void 0;
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var useDidMount_1 = require("../hooks/useDidMount");
var context_1 = require("./context");
var initState = {
    initialState: undefined,
    loading: true,
    error: undefined,
};
function AppProvider(props) {
    var _this = this;
    var children = props.children, getInitialState = props.getInitialState;
    var _a = tslib_1.__read(react_1.default.useState(initState), 2), state = _a[0], setState = _a[1];
    var refreshInitialState = react_1.useCallback(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var asyncFunc, ret_1, e_1;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    asyncFunc = function () { return new Promise(function (res) { return res(getInitialState()); }); };
                    return [4 /*yield*/, asyncFunc()];
                case 1:
                    ret_1 = _a.sent();
                    setState(function (prevState) { return (tslib_1.__assign(tslib_1.__assign({}, prevState), { initialState: ret_1, loading: false })); });
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    setState(function (prevState) { return (tslib_1.__assign(tslib_1.__assign({}, prevState), { error: e_1, loading: false })); });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    useDidMount_1.useDidMount(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            refreshInitialState();
            return [2 /*return*/];
        });
    }); });
    var setInitialState = react_1.useCallback(function (initialState) {
        setState(function (prevState) {
            var prevInitialState = prevState.initialState;
            return tslib_1.__assign(tslib_1.__assign({}, prevState), { initialState: tslib_1.__assign(tslib_1.__assign({}, prevInitialState), initialState), loading: false });
        });
    }, [state]);
    return (react_1.default.createElement(context_1.AppContext.Provider, { value: tslib_1.__assign(tslib_1.__assign({}, state), { refreshInitialState: refreshInitialState,
            setInitialState: setInitialState }) }, children));
}
exports.AppProvider = AppProvider;
