export const defaultResponseMessages = {
    PhoneVerifyFailedException: "短信验证码错误",

    ProductCategoryDoesNotExists: "商品分类不存在,可能已被删除",
    ProductCategoryHasAssigned: "无法删除已关联的商品分类",

    ArticleCategoryDoesNotExists: "文章分类不存在,可能已被删除",
    ArticleCategoryHasAssigned: "无法删除已关联的文章分类",

    MaterialCategoryDoesNotExists: "素材分类不存在,可能已被删除",
    MaterialCategoryHasAssigned: "此分类下存在素材，无法删除",
};
