import React from 'react';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter } from 'react-router-dom';

import AsyncComponent from '@/components/AsyncComponent';
export const routers = [
    {
        path: "/login",
        component: require("@/pages/login").default,
    },

    {
        path: "/",
        component: require("@/layouts").default,
        routes: [
            {
                path: "/products",
                component: AsyncComponent(() => import("@/pages/Products")),
            },
            {
                path: "/material",
                component: AsyncComponent(() => import("@/pages/Material")),
            },
            {
                path: "/articles",
                component: AsyncComponent(() => import("@/pages/Articles")),
            },
            {
                path: "/marketing",
                component: AsyncComponent(() => import("@/pages/Marketing")),
            },
            {
                path: "/transaction",
                component: AsyncComponent(() => import("@/pages/Transactions")),
            },
            {
                path: "/storage",
                component: AsyncComponent(() => import("@/pages/Storage")),
            },
            {
                path: "/users",
                component: AsyncComponent(() => import("@/pages/Users")),
            },
            {
                path: "/system",
                component: AsyncComponent(() => import("@/pages/System")),
            },
            {
                path: "/membersystem",
                component: AsyncComponent(() => import("@/pages/MemberSystem")),
            },
            {
                path: "/recycle",
                component: AsyncComponent(() => import("@/pages/RecycleBin")),
            },
            {
                path: "/logistics",
                component: AsyncComponent(() => import("@/pages/Logistics")),
            },
            {
                path: "/news",
                component: AsyncComponent(() => import("@/pages/News")),
            },
            {
                path: "/capital",
                component: AsyncComponent(() => import("@/pages/Capital")),
            },
            {
                path: "/operationlog",
                component: AsyncComponent(() => import("@/pages/OperationLog")),
            },
            {
                path: "/permission",
                component: AsyncComponent(() => import("@/pages/Permission")),
            },
            {
                // extra: true,
                path: "/dashboard",
                component: require("@/pages/Dashboard").default,
            },
        ],
    },
];

export function AppRoutes() {
    return <BrowserRouter>{renderRoutes(routers)}</BrowserRouter>;
}
