let numList  = [
    [
        {title: '总用户', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '普通用户', value: '0', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '一级会员', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '二级会员', value: '8145', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '三级会员', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
]

let orderTransactionList = [
    [
        {title: '全部订单', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '待支付订单', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '关闭订单量', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '待发货订单', value: '8145', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '待收货订单', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '已完成订单', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '订单总金额', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '订单待付总金额', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '订单已支付总金额', value: '10000', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
]

let priceList = [
    [
        {title: '会员收益总额', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '会员收益待兑付总额', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
    [
        {title: '推广奖励总额', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '会员收益待入账总额', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
    [
        {title: '分销奖励总额', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '分销待入账总额', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
    [
        {title: '会员已兑付总额', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '推广收入总额', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '分销入账总额', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
]

let wineStorageList = [
    [
        {title: '商城累计存酒', value: '18112', name: '', suffix: '', hasBtn: false, btnText: ''},
        {title: '商城已兑付存酒', value: '1002', name: '', suffix: '', hasBtn: false, btnText: ''},
    ],
]

export let indexNumList = [
    {title: "用户概览", list: numList},
    {title: "订单交易概览", list: orderTransactionList},
    {title: "资金概览", list: priceList},
    {title: "存酒概览", list: wineStorageList},
];