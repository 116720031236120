import { ModalProps } from 'antd/lib/modal';
import { useRef, useState } from 'react';
import { useDialog } from 'react-dialog-context';

type DialogActions = {
    close: (result?: any) => void;
    loading: (loading?: boolean) => void;
};

type OkCallback = (actions: DialogActions) => void;

export function useAntdDialog(okCallback?: OkCallback): [ModalProps, DialogActions] {
    const [visible, setVisible] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const resultRef = useRef(null);
    const dialog = useDialog();
    const afterClose = () => {
        dialog.close(resultRef.current);
    };

    const actions = {
        loading(loading) {
            setConfirmLoading(loading);
        },
        close(result) {
            resultRef.current = result;
            setVisible(false);
        },
    };

    return [
        {
            visible,
            destroyOnClose: true,
            afterClose,
            confirmLoading,
            onOk() {
                if (okCallback) {
                    okCallback(actions);
                } else {
                    setVisible(false);
                }
            },
            onCancel() {
                setVisible(false);
            },
        },
        actions,
    ];
}

export default useAntdDialog;
