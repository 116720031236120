"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDidMount = void 0;
var react_1 = require("react");
function useDidMount(fn) {
    react_1.useEffect(function () {
        fn();
    }, []);
}
exports.useDidMount = useDidMount;
