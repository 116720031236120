"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryParams = void 0;
var react_1 = require("react");
var react_router_1 = require("react-router");
var core_1 = require("@jsmodules/core");
function useQueryParams() {
    var location = react_router_1.useLocation();
    var queryParams = react_1.useMemo(function () {
        return new core_1.URLSearchParams(location.search);
    }, [location.search]);
    return queryParams;
}
exports.useQueryParams = useQueryParams;
