import './index.less';

import { Button, Form, Input, message, Modal } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import AppLogo from '@/components/AppLogo';
import CaptchaImage from '@/components/Captcha/Image';
import CaptchaPhone from '@/components/Captcha/Phone';
import { GatewayOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { SessionService } from '@jsmodules/core';
import { useAuthenticated, useResolveClass } from '@jsmodules/react';
import { menusList } from '@/routers/menus';
import { MeApi } from '@shared/api/dist/generated/api_v1';

interface ILoginProps {}

export const Login: React.FC<ILoginProps> = (props: ILoginProps) => {
    const sessionService = useResolveClass(SessionService);
    const { setAuthenticated } = useAuthenticated();
	const api = useResolveClass(MeApi);

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const captchaSigRef = useRef("nouse");
    const [verifyCodeSig, setVerifyCodeSig] = useState("");
    const handleLogin = useCallback(
        async (values) => {
            try {
                if (!verifyCodeSig) {
                    message.error("请发送手机验证码");
                    return;
                }
                if (!captchaSigRef.current) {
                    message.error("请刷新图形验证码");
                    return;
                }
                setLoading(true);
                const res = await sessionService.loginAsync({
                    type: "phone",
                    data: {
                        captchaSig: captchaSigRef.current,
                        verifyCodeSig,
                        isConsole:true,
                        ...values,
                    },
                });
				api.account().get().then((resp) => {
					let userInfo:any = {};
					userInfo = resp.data;
					localStorage.setItem("userInfo", JSON.stringify(userInfo));
					let newArr: any = [];
					let scopes = userInfo.scopes;
					if (userInfo.scopes.indexOf('All') != -1) {
						newArr = menusList
					} else {
						for (let i = 0; i < menusList.length; i++) {
							const list = menusList[i];
							const menuScopes = list.meta.scopes;
							if (scopes.indexOf(menuScopes) != -1) {
								newArr.push(list)
							}
						}
					}
					setAuthenticated(true);
					if (newArr[0]?.path) {
						history.replace(newArr[0]?.path);
					} else {
						history.replace(newArr[0].menus[0]?.path);
					}
				});
            } catch (err) {
                setLoading(false);
                console.error(err);
            }
        },
        [history, sessionService, setAuthenticated, verifyCodeSig]
    );

    const [form] = Form.useForm();

    return (
        <div className="login-page">
            <div className="login-content">
                <div className="login-top">
                    <div className="login-title">
                        <AppLogo />
                    </div>
                    <div className="login-desc"></div>
                </div>
                <Form form={form} onFinish={handleLogin}>
                    <Form.Item name="phoneNumber" rules={[{ required: true, message: "请输入手机号!" }]}>
                        <Input
                            size="large"
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                            maxLength={11}
                            prefix={<MobileOutlined />}
                            placeholder="登录手机号"
                        />
                    </Form.Item>
                    <Form.Item name="verifyCode" rules={[{ required: true, message: "请输入短信验证码!" }]}>
                        <Input
                            autoComplete={__DEV__ ? "on" : "off"}
                            disabled={verifyCodeSig === ""}
                            className="login-verifycode"
                            size="large"
                            prefix={<MailOutlined />}
                            addonAfter={<CaptchaPhone phoneNumber={phoneNumber} onSigUpdate={setVerifyCodeSig} />}
                            placeholder="短信验证码"
                        />
                    </Form.Item>
                    {/* <Form.Item name="captcha" rules={[{ required: true, message: "请输入图形验证码!" }]}>
                        <Input
                            autoComplete="off"
                            size="large"
                            className="login-captcha"
                            prefix={<GatewayOutlined />}
                            addonAfter={
                                <CaptchaImage
                                    imageProps={{
                                        className: "login-captcha-img",
                                    }}
                                    onSigUpdate={(sig) => {
                                        captchaSigRef.current = sig;
                                    }}
                                />
                            }
                            placeholder="图形验证码"
                        />
                    </Form.Item> */}
                    <Form.Item>
                        <Button
                            size="large"
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                            className="login-submit"
                        >
                            {loading ? "登录中..." : "登录"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
