"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shipCompanys = exports.shipCompanyMap = void 0;
exports.shipCompanyMap = {
    ZiTi: '自提',
    ZhongTong: "中通快递",
    YuanTong: "圆通快递",
    ShunFeng: "顺丰快递",
    TianTian: "天天快递",
    ShenTong: "申通快递",
    HuiTong: "百世汇通",
    YunDa: "韵达快递",
    DeBang: "德邦快递",
    ZhaiJiSong: "宅急送",
    Ems: "邮政EMS",
};
exports.shipCompanys = Object.keys(exports.shipCompanyMap);
