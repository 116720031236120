"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccessProvider = void 0;
var tslib_1 = require("tslib");
var react_1 = tslib_1.__importStar(require("react"));
var useInitialState_1 = require("../app/useInitialState");
var context_1 = require("./context");
function AccessProvider(props) {
    var children = props.children, accessFactory = props.accessFactory;
    var initialState = useInitialState_1.useInitialState().initialState;
    var access = react_1.useMemo(function () {
        return accessFactory(initialState);
    }, [initialState]);
    return react_1.default.createElement(context_1.AccessContext.Provider, { value: access }, children);
}
exports.AccessProvider = AccessProvider;
