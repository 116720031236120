import { Affix } from 'antd';
import classNames from 'classnames';
import * as React from 'react';

import css from './index.module.less';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    disableAffix?: boolean;
}

export function ToolbarFilled() {
    return <div style={{ flex: 1 }}></div>;
}

export default function PageToolbar(props: IProps) {
    const { disableAffix, className, children, ...otherProps } = props;

    if (disableAffix) {
        return (
            <div className={classNames(css.pageToolbar, className)} {...otherProps}>
                {children}
            </div>
        );
    }

    return (
        <Affix offsetTop={64} className={css.affixToolbar}>
            <div className={classNames(css.pageToolbar, className)} {...otherProps}>
                {children}
            </div>
        </Affix>
    );
}
