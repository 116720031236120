"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var binding_1 = require("./binding");
var Container = /** @class */ (function () {
    function Container() {
        this.m = new Map();
    }
    Container.prototype.getNames = function () {
        return this.m.keys();
    };
    Container.prototype.bind = function (name) {
        if (!this.m.has(name)) {
            var __def__ = new binding_1.Binding(name);
            this.m.set(name, __def__);
        }
        return this.m.get(name);
    };
    Container.prototype.has = function (name) {
        return this.m.has(name);
    };
    Container.prototype.get = function (name) {
        if (this.has(name)) {
            return this.m.get(name);
        }
        return null;
    };
    Container.prototype.resolve = function (name) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var binding = this.get(name);
        if (binding) {
            return binding.resolve.apply(binding, tslib_1.__spread(args));
        }
        return null;
    };
    return Container;
}());
exports.default = Container;
