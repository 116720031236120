"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pipeline = void 0;
var tslib_1 = require("tslib");
var Pipeline = /** @class */ (function () {
    function Pipeline() {
        this.callbacks = [];
    }
    Pipeline.prototype.use = function (callbackFn) {
        this.callbacks.push(callbackFn);
        return this;
    };
    Pipeline.prototype.exec = function (input, output) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, _a, _b, callback, e_1_1;
            var e_1, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        result = output;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        _a = tslib_1.__values(this.callbacks), _b = _a.next();
                        _d.label = 2;
                    case 2:
                        if (!!_b.done) return [3 /*break*/, 5];
                        callback = _b.value;
                        return [4 /*yield*/, callback(input, result)];
                    case 3:
                        result = _d.sent();
                        _d.label = 4;
                    case 4:
                        _b = _a.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/, result];
                }
            });
        });
    };
    Pipeline.prototype.execSync = function (input, output) {
        var e_2, _a;
        var result = output;
        try {
            for (var _b = tslib_1.__values(this.callbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
                var callback = _c.value;
                result = callback(input, result);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return result;
    };
    return Pipeline;
}());
exports.Pipeline = Pipeline;
