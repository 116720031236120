"use strict";
//@ts-nocheck
Object.defineProperty(exports, "__esModule", { value: true });
exports.appConfig = void 0;
var tslib_1 = require("tslib");
var _appConfig = {
    webHost: "https://club.wocun9.com",
};
if (API_ENV === "dev") {
    _appConfig.webHost = "http://fftest.eeeban.com";
}
if (__DEV__) {
    _appConfig = tslib_1.__assign(tslib_1.__assign({}, _appConfig), { webHost: "http://fftest.eeeban.com" });
}
exports.appConfig = _appConfig;
