import React, { useRef } from 'react';

import { useInitialState } from '@jsmodules/react';

// import { sleepAsync } from '@jsmodules/core';
import { AppLogoContext } from '../AppLogo/context';
import css from './index.module.less';

interface IProps {
    children: any;
}

export function SplashScreen(props: IProps) {
    const { children } = props;
    const { loading } = useInitialState();
    const maskEl = useRef<HTMLDivElement>(null);
    const hideSplashScreen = (logo: HTMLElement) => {
        return new Promise((resolve) => {
            requestAnimationFrame(() => {
                const el = maskEl.current;
                // const rect = logo.getBoundingClientRect();
                // if (img) {
                //     img.style.display = "none";
                //     img.style.transform = `none`;
                //     img.style.visibility = "visible";
                //     img.style.zIndex = `99999`;
                //     img.style.width = `${rect.width}px`;
                //     img.style.height = `${rect.height}px`;
                //     img.style.left = `${rect.left}px`;
                //     img.style.top = `${rect.top}px`;
                // }
                // sleepAsync(510).then(() => {
                if (el) {
                    el.style.zIndex = `0`;
                    el.style.visibility = "hidden";
                }
                // });
                resolve();
            });
        });
    };

    return (
        <AppLogoContext.Provider
            value={{
                hideSplashScreen: hideSplashScreen,
            }}
        >
            {!loading && <>{children}</>}
            <div ref={maskEl} className={css.splashscreen}>
                <img className={css.splashLogo} src={require("../../images/logo1.png")} alt="logo" />
            </div>
        </AppLogoContext.Provider>
    );
}

export default SplashScreen;
