import { Button, message } from 'antd';
import React, { useCallback, useState } from 'react';

import di from '@jsmodules/di';
import { useIsMounted } from '@jsmodules/react';
import CaptchaApi from '@shared/api/dist/generated/v1/captcha';

interface ICaptchaPhoneProps {
    phoneNumber;
    onSigUpdate?: (sig) => void;
}
export const CaptchaPhone = (props: ICaptchaPhoneProps) => {
    const { phoneNumber, onSigUpdate } = props;

    const [countdown, setCountdown] = useState(0);

    const isMounted = useIsMounted();
    const [sending, setSending] = useState(false);
    const updateCountdown = useCallback(
        (val = 60) => {
            if (isMounted.current) {
                setCountdown(val);
                if (val > 0) {
                    setTimeout(() => {
                        updateCountdown(val - 1);
                    }, 1000);
                }
            }
        },
        [isMounted]
    );

    const updateCaptcha = useCallback(async () => {
        try {
            if (!phoneNumber) {
                message.error("请输入电话号码再发送验证码");
                return;
            }
            if (countdown > 0) {
                message.error("距离上次发送时间太短，请稍后再试");
                return;
            }
            setSending(true);
            const captchaApi = di.getInstance(CaptchaApi);
            const resp = await captchaApi.phoneByPhoneNumber(phoneNumber).post();
            updateCountdown();
            setSending(false);
            onSigUpdate && onSigUpdate(resp.data);
            message.success("已发送验证码");
        } catch (ex) {
            setSending(false);
            message.error("发送验证码失败，请稍后再试");
        }
    }, [countdown, onSigUpdate, phoneNumber, updateCountdown]);

    let disabled = countdown > 0 || sending;
    if ((phoneNumber || "").length !== 11) {
        disabled = true;
    }
    return (
        <Button type="link" disabled={disabled} onClick={updateCaptcha}>
            {sending ? "正在发送..." : countdown > 0 ? `重发验证码(${countdown})` : "发送验证码"}
        </Button>
    );
};

export default CaptchaPhone;
