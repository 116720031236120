"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EncryptedKeyValue = exports.KeyValue = void 0;
exports.KeyValue = {
    name: "kv_data",
    schema: {
        title: "KeyValue Schema",
        description: "KeyValuePair Storage",
        version: 0,
        type: "object",
        properties: {
            id: {
                type: "string",
                primary: true,
            },
            key: {
                type: "string",
            },
            group: {
                type: "string",
            },
            value: {
                type: "object",
            },
        },
    },
};
exports.EncryptedKeyValue = {
    name: "encrypted_kv_data",
    schema: {
        title: "Encrypted KeyValue Schema",
        description: "Encrypted KeyValuePair Storage",
        version: 0,
        type: "object",
        properties: {
            id: {
                type: "string",
                primary: true,
            },
            key: {
                type: "string",
            },
            group: {
                type: "string",
            },
            value: {
                type: "object",
            },
        },
        encrypted: ["value"],
    },
};
