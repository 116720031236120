import React, { useContext, useEffect, useRef } from 'react';

import { AppLogoContext } from './context';

interface IProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<any>, HTMLImageElement> {}

export const AppLogo: React.FC<IProps> = (props: IProps) => {
    const context = useContext(AppLogoContext);
    const logoRef = useRef<any>();
    useEffect(() => {
        context.hideSplashScreen(logoRef.current).then(() => {
            logoRef.current.style.opacity = 1;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <img ref={logoRef} style={{ opacity: 0 }} src={require("../../images/logo1.png")} alt="logo" {...props} />;
};

export default AppLogo;
