import './index.less';

import { Badge, Dropdown, Layout, Menu, Tooltip } from 'antd';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import AntdDialogHost from '@/components/AntdDialogHost';
import AppLogo from '@/components/AppLogo';
import AutoAvatar from '@/components/Avatar';
import MainMenu from '@/components/Menu';
import { BellOutlined, LogoutOutlined, QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SessionService } from '@jsmodules/core';
import di from '@jsmodules/di';
import { useAuthenticated, useLoginedUser } from '@jsmodules/react';

interface ILayoutProps extends RouteComponentProps {
    children: any;
    route?: any;
}

export const MainLayout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
    const { route } = props;
    const location = useLocation();
    const history = useHistory();
    const { isAuthenticated } = useAuthenticated();
    const handleMenuClick = (e) => {
        const handles = {
            profile: () => {
                alert("个人设置");
            },
            logout: () => {
                const session = di.getInstance(SessionService);
                session.logoutAsync().then(() => {
                    history.replace("/login");
					// localStorage.setItem("userInfo", '');
                });
            },
        };
        if (handles[e.key]) {
            handles[e.key]();
        }
    };

    const user: any = useLoginedUser();
    if (!isAuthenticated) {
        const next = location.pathname + location.search;
        return <Redirect to={`/login?next=${encodeURIComponent(next)}`} push={false} />;
    }

    return (
        <Layout className="main-layout">
            <Layout.Header className="main-header">
                <div className="navbar">
                    <div className="logo">
                        <AppLogo />
                    </div>
                    {/* <Tooltip title="帮助中心">
                        <span className="action">
                            <QuestionCircleOutlined />
                        </span>
                    </Tooltip>
                    <Tooltip title="系统消息">
                        <span className="action">
                            <Badge text="2">
                                <BellOutlined />
                            </Badge>
                        </span>
                    </Tooltip> */}
                    <Dropdown
                        placement="bottomRight"
                        overlay={
                            <Menu className="user-menu" onClick={handleMenuClick}>
                                <Menu.Item key="logout">
                                    <LogoutOutlined />
                                    退出登录
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <span className="action user-info">
                            <AutoAvatar size="small" src="https://club.wocun9.com/logo.png" />
                            {/* <AutoAvatar size="small" icon={<UserOutlined />} /> */}
                            <span className="nickname">{user?.phone || ""}</span>
                        </span>
                    </Dropdown>
                </div>
            </Layout.Header>
            <Layout className="main-content-layout">
                <Layout.Sider breakpoint="lg" collapsedWidth={0} width={256} className="main-sider">
                    <MainMenu />
                </Layout.Sider>
                <Layout.Content className="main-content">
                    {renderRoutes(route.routes)}
                    <AntdDialogHost name="drawer" />
                </Layout.Content>
                <div id="drawer-host" className="drawer-host"></div>
            </Layout>
        </Layout>
    );
};

export default MainLayout;
