import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useResolveClass } from '@jsmodules/react';

import { menusList } from '@/routers/menus';
import { useAccess } from '@jsmodules/react';
import { MeApi } from '@shared/api/dist/generated/api_v1';


import { LockOutlined, LaptopOutlined, DollarCircleOutlined, BellOutlined, AccountBookOutlined, BankOutlined, CreditCardOutlined, DashboardOutlined, FileImageOutlined, SettingOutlined, TransactionOutlined, UnorderedListOutlined, UserOutlined, ClearOutlined, CarOutlined } from '@ant-design/icons';
import any from '*.less';

interface IMainMenuProps {}
export const MainMenu = (props: IMainMenuProps) => {
	console.log('已经生成了路由')
	let userInfo: any = JSON.parse(localStorage.getItem("userInfo") || 'null');
	const api = useResolveClass(MeApi);
	if (!userInfo) {
		api.account().get().then((resp) => {
			userInfo = resp.data;
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
		});
	}

    // debugger;
	let newArr:any = [];
	let scopes = userInfo.scopes;
	if (scopes.indexOf('All') != -1) {
		newArr = menusList;
	} else {
		for (let i = 0; i < menusList.length; i++) {
            const list = menusList[i];
            const menuScopes = list.meta.scopes;
            if (scopes.indexOf(menuScopes) != -1) {
				newArr.push(list)
			}
        }
	}
	let menus = newArr;
	// let menus = menusList;

    const location = useLocation();

    const access = useAccess();

    let menuPath = (location.pathname || "").replace(/^\//, "").replace(/\/$/, "");

    let openKeys: string[] = [];
    let selectedKeys: string[] = [];
    if (!menuPath) {
        openKeys = ["home"];
        selectedKeys = ["_home"];
    } else {
        openKeys = menuPath.split("/");

        selectedKeys = [`_${menuPath.replace(/\//, "_")}`];
        let selectedPath = "";
        for (const key of openKeys) {
            selectedPath += "_" + key;
            selectedKeys.push(selectedPath);
        }
    }

    console.log("selectKey", selectedKeys);

    const menuItems = useMemo(() => {
        const renderMenu = (menus, path = "") => {
            let components: any = [];
            for (const item of menus) {
                const hasAccess = access.hasAccess(item.key);
                if (!hasAccess) {
                    continue;
                }
                let menuKey = path + "_" + item.key;
                if (item.key === "$index") {
                    menuKey = path;
                }
                console.log(menuKey);
                if (item.menus) {
                    components.push(
                        <Menu.SubMenu key={item.key} title={item.title} icon={item.icon}>
                            {renderMenu(item.menus, menuKey)}
                        </Menu.SubMenu>
                    );
                } else {
                    components.push(
                        <Menu.Item key={menuKey} icon={item.icon}>
                            <Link to={item.path}>{item.title}</Link>
                        </Menu.Item>
                    );
                }
            }
            return components;
        };

        return renderMenu(menus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Menu className="main-menu" mode="inline" theme="dark" defaultOpenKeys={openKeys} selectedKeys={selectedKeys}>
            {menuItems}
        </Menu>
    );
};

export default MainMenu;
