"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.events = exports.eventCenter = exports.Events = exports.EventSubscription = void 0;
var tslib_1 = require("tslib");
var events_1 = require("events");
var EventSubscription = /** @class */ (function () {
    function EventSubscription(emmiter, eventName) {
        this.emmiter = emmiter;
        this.eventName = eventName;
        this.callbacks = [];
    }
    EventSubscription.prototype.then = function (callback) {
        this.callbacks.push(callback);
        this.emmiter.on(this.eventName, callback);
        return this;
    };
    EventSubscription.prototype.once = function (callback) {
        var _this = this;
        var onceCallback = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            callback.apply(void 0, tslib_1.__spread(args));
            _this.callbacks = _this.callbacks.filter(function (f) { return f != onceCallback; });
        };
        this.callbacks.push(onceCallback);
        this.emmiter.once(this.eventName, onceCallback);
        return this;
    };
    EventSubscription.prototype.count = function () {
        return this.emmiter.listenerCount(this.eventName);
    };
    EventSubscription.prototype.remove = function () {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(this.callbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
                var callback = _c.value;
                this.emmiter.removeListener(this.eventName, callback);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    return EventSubscription;
}());
exports.EventSubscription = EventSubscription;
var Events = /** @class */ (function () {
    function Events() {
        this.emitter = new events_1.EventEmitter();
    }
    Events.prototype.on = function (eventName, callback) {
        var subscription = new EventSubscription(this.emitter, eventName);
        if (callback) {
            return subscription.then(callback);
        }
        return subscription;
    };
    Events.prototype.removeAllSubscription = function (eventName) {
        this.emitter.removeAllListeners(eventName);
    };
    Events.prototype.emit = function (eventName) {
        var _a;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        (_a = this.emitter).emit.apply(_a, tslib_1.__spread([eventName], args));
    };
    Events.prototype.proxy = function (eventName) {
        var _this = this;
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            _this.emit.apply(_this, tslib_1.__spread([eventName], args));
        };
    };
    return Events;
}());
exports.Events = Events;
exports.eventCenter = new Events();
exports.events = {
    eventCenter: exports.eventCenter,
    emitter: function () {
        return new events_1.EventEmitter();
    },
};
