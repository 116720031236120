import { SessionService } from "@jsmodules/core";
import di from "@jsmodules/di";
import { message, Modal } from "antd";
import axios from "axios";

export const setupAxios = () => {
  axios.interceptors.response.use(function (response) {
      return response;
    }, 
    function (error) {
      switch(error.response.status)
      {
        case 400:
          var data=error.response.data;
          var content='';
          switch(data.type){
            case 'WebApiException':
              switch(data.code){
                case 1003:
                  content = '手机号码已被注册';
                  break;
                case 1201:
                  content = '素材分类已存在';
                  break;
                case 1015:
                  content = '电话号码不存在';
                  break;
                case 1012:
                  content = "短信验证码错误";
                    break;
                case 1011:
                  content = "图形验证码错误";
                    break;
                case 1018:
                  content = "身份验证失败";
                    break;
              }
              break;
            case 'ECommerceException':
              switch(data.code){
                  case 40002:
                    content='商品分类已存在';
                    break;
                  case 20004:
                    content='优惠券无效';
                    break;
                  case 20003:
                    content='用户已领取过此优惠券';
                    break;
                  case 50006:
                    content='付款金额与订单金额不符';
                    break
              }
              break;
          }
          Modal.warn({
            content: content||data.message,
          });
          break;
        case 401:
          const session = di.getInstance(SessionService);
          session.logoutAsync().then(() => {
              window.location.href="/login";
          });
          break;
      }
      return Promise.reject(error);
    }
  );
}