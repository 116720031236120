"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
//生成时间:2021/5/16 下午5:11:23
tslib_1.__exportStar(require("./v1/account"), exports);
tslib_1.__exportStar(require("./v1/addressinfo"), exports);
tslib_1.__exportStar(require("./v1/agentapply"), exports);
tslib_1.__exportStar(require("./v1/article"), exports);
tslib_1.__exportStar(require("./v1/articlecategory"), exports);
tslib_1.__exportStar(require("./v1/bankcard"), exports);
tslib_1.__exportStar(require("./v1/captcha"), exports);
tslib_1.__exportStar(require("./v1/coupon"), exports);
tslib_1.__exportStar(require("./v1/logistic"), exports);
tslib_1.__exportStar(require("./v1/material"), exports);
tslib_1.__exportStar(require("./v1/materialcategory"), exports);
tslib_1.__exportStar(require("./v1/me"), exports);
tslib_1.__exportStar(require("./v1/membersystem"), exports);
tslib_1.__exportStar(require("./v1/mp"), exports);
tslib_1.__exportStar(require("./v1/notification"), exports);
tslib_1.__exportStar(require("./v1/operationlog"), exports);
tslib_1.__exportStar(require("./v1/order"), exports);
tslib_1.__exportStar(require("./v1/posbot"), exports);
tslib_1.__exportStar(require("./v1/production"), exports);
tslib_1.__exportStar(require("./v1/productioncategory"), exports);
tslib_1.__exportStar(require("./v1/recyclebin"), exports);
tslib_1.__exportStar(require("./v1/setting"), exports);
tslib_1.__exportStar(require("./v1/sharereferal"), exports);
tslib_1.__exportStar(require("./v1/statistic"), exports);
tslib_1.__exportStar(require("./v1/storage"), exports);
tslib_1.__exportStar(require("./v1/test"), exports);
tslib_1.__exportStar(require("./v1/wallet"), exports);
tslib_1.__exportStar(require("./v1/wechat"), exports);
tslib_1.__exportStar(require("./v1/wxpayment"), exports);
