"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Binding = void 0;
var tslib_1 = require("tslib");
var cached = {};
var Binding = /** @class */ (function () {
    function Binding(name) {
        this.name = name;
        this.scope = "Singleton";
        this._params = [];
    }
    Binding.prototype.to = function (typedef) {
        var _this = this;
        this._typedef = typedef;
        this.getInstance = function () {
            var _a;
            var pamams = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                pamams[_i] = arguments[_i];
            }
            var instance = new ((_a = _this._typedef).bind.apply(_a, tslib_1.__spread([void 0], pamams)))();
            return instance;
        };
        return this;
    };
    Binding.prototype.toValue = function (val) {
        var _this = this;
        this._instance = val;
        this.getInstance = function () {
            return _this._instance;
        };
        return this;
    };
    Binding.prototype.toFactory = function (factory) {
        this.getInstance = factory;
        return this;
    };
    Binding.prototype.isSingletonScope = function () {
        this.setScope("Singleton");
    };
    Binding.prototype.isTransientScope = function () {
        this.setScope("Transient");
    };
    Binding.prototype.isRequestScope = function () {
        this.setScope("Request");
    };
    Binding.prototype.setScope = function (scope) {
        this.scope = scope;
        return this;
    };
    Binding.prototype.params = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        this._params = args;
        return this;
    };
    Binding.prototype.resolve = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var params = tslib_1.__spread(this._params, args);
        if (this.scope == "Singleton") {
            var key = "di_" + this.name + "_signle";
            var instance = cached[key];
            if (!instance) {
                instance = cached[key] = this.getInstance.apply(this, tslib_1.__spread(params));
            }
            return instance;
        }
        if (this.scope == "Request") {
            var key = "di_" + this.name + "_" + params.join("-");
            var instance = cached[key];
            if (!instance) {
                instance = cached[key] = this.getInstance.apply(this, tslib_1.__spread(params));
            }
            return instance;
        }
        return this.getInstance.apply(this, tslib_1.__spread(params));
    };
    return Binding;
}());
exports.Binding = Binding;
exports.default = Binding;
