import React, { useEffect, useReducer } from 'react';
import { dialog, IDialog, ReactDialog } from 'react-dialog-context';

interface IAntdDialogHostProps {
    name?: string;
}

type DialogHostState = {
    dialogs: IDialog[];
};

const dialogReducer = function (state, action): DialogHostState {
    if (action.type === "add") {
        return { dialogs: [...state.dialogs, action.payload] };
    }
    if (action.type === "remove") {
        return {
            dialogs: state.dialogs.filter((dialog) => dialog.id !== action.payload.id),
        };
    }
    return state;
};

export const AntdDialogHost: React.FC<IAntdDialogHostProps> = (props: IAntdDialogHostProps) => {
    const [state, dispatch] = useReducer(dialogReducer, { dialogs: [] });
    useEffect(() => {
        const name = props.name || "default";
        dialog.addContext(name, {
            async addHost(theDialog: IDialog) {
                dispatch({
                    type: "add",
                    payload: theDialog,
                });
            },
            async removeHost(theDialog: IDialog) {
                dispatch({
                    type: "remove",
                    payload: theDialog,
                });
            },
        });

        return () => {
            dialog.removeContext(name);
        };
    }, [props.name]);
    const { dialogs } = state;
    return (
        <>
            {dialogs.map((dialog) => {
                return <ReactDialog key={dialog.id} dialog={dialog} />;
            })}
        </>
    );
};

export default AntdDialogHost;
