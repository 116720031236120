import React, { useEffect, useState, useCallback } from 'react';
import { PageHeader, Button, Modal } from 'antd';

import PageContent from '@/components/Page/Content';
import StatisticsModel from '@/components/StatisticsModel/statisticsModel'

import Chart1 from './components/Chart1';
import { indexNumList } from './util/overView'
import { useResolveClass, useDidMount } from '@jsmodules/react';
import { StatisticApi } from '@shared/api/dist/generated/api_v1';
import ButtonGroup from 'antd/lib/button/button-group';
import PageToolbar, { ToolbarFilled } from '@/components/Page/Toolbar';
import { rest } from 'lodash';

interface IProps {}
export const Dashboard = (props: IProps) => {

    const statApi = useResolveClass(StatisticApi);
    const [list, setList] = useState<any>(indexNumList);
    const [sendModalVisible,setSendModalVisible]=useState<boolean>(false);

    useDidMount(async () => {
        let accountSummary = statApi.summary().get();
        let bylevel = statApi.bylevel().get();
        let orderCountSummary = statApi.orderCountSummary().get();
        let storagecount = statApi.storagecount().get();

        var rets= await Promise.all([accountSummary, bylevel, orderCountSummary, storagecount]);
        const data = rets[0].data;
        const numData = rets[1].data;
        const orderData = rets[2].data;
        const storageData = rets[3].data;
        console.log(storageData)
        
        let priceList =  [
            [
                {title: '会员返利计划总额', precision: 2, value: data.memberRebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '会员返利计划待入账总额', precision: 2, value: data.memberUnrebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '会员返利计划入账总额', precision: 2, value: (data.memberRebate - data.memberUnrebate) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
            ],
            [
                {title: '推广收益总额', precision: 2, value: data.registRebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '推广收益待入账总额', precision: 2, value: data.registUnrebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '推广收益已入账总额', precision: 2, value: (data.registRebate - data.registUnrebate) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
            ],
            [
                {title: '分销奖励总额', precision: 2, value: data.saleRebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '分销奖励待入账总额', precision: 2, value: data.saleUnrebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '分销奖励已入账总额', precision: 2, value: (data.saleRebate - data.saleUnrebate) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
            ],
            [
                {title: '平台应兑付总额', precision: 2, value: data.totalRebate / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '平台待兑付总额', precision: 2, value: (data.totalRebate - (data.memberRebate - data.memberUnrebate) - (data.registRebate - data.registUnrebate) - (data.saleRebate - data.saleUnrebate)) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
				// {title: '平台已兑付总额', precision: 2, value: (data.totalRebate - (data.balance + Math.abs(data.withdraw))) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
				{title: '平台已兑付总额', precision: 2, value: (data.balance + Math.abs(data.withdraw)) / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
			],
			[
				{title: '用户待提现总额', precision: 2, value: data.balance / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '用户已提现总额', precision: 2, value: Math.abs(data.withdraw / 100), name: '', suffix: '元', hasBtn: false, btnText: ''},
			]
        ]

        let numList  = [
            [
                {title: '总用户', precision: 0, value: numData.totalCount, name: '', suffix: '人', hasBtn: false, btnText: ''},
                {title: '普通用户', precision: 0, value: numData.normalCount, name: '', suffix: '人', hasBtn: false, btnText: ''},
                {title: '一级会员', precision: 0, value: numData.level1Count, name: '', suffix: '人', hasBtn: false, btnText: ''},
                {title: '二级会员', precision: 0, value: numData.level2Count, name: '', suffix: '人', hasBtn: false, btnText: ''},
                {title: '三级会员', precision: 0, value: numData.level3Count, name: '', suffix: '人', hasBtn: false, btnText: ''},
            ],
        ]
        
        let orderList = [
            [
                {title: '全部订单', precision: 0, value: orderData.totalCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '待支付订单', precision: 0, value: orderData.createdCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '关闭订单量', precision: 0, value: orderData.canceledCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '待发货订单', precision: 0, value: orderData.paidCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '待收货订单', precision: 0, value: orderData.sentCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '已完成订单', precision: 0, value: orderData.finishedCount, name: '', suffix: '', hasBtn: false, btnText: ''},
            ],
        ]

		let transactionList = [
			[
				{title: '订单总金额', precision: 2, value: orderData.totalAmount / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '订单待付总金额', precision: 2, value: orderData.createdAmount / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
                {title: '订单已支付总金额', precision: 2, value: orderData.paidAmount / 100, name: '', suffix: '元', hasBtn: false, btnText: ''},
			]
		]
        
        
        let wineStorageList = [
            [
                {title: '商城累计存酒', precision: 0, value: storageData.storageCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '商城已兑付存酒', precision: 0, value: storageData.storageTakeCount, name: '', suffix: '', hasBtn: false, btnText: ''},
                {title: '商城待兑付存酒', precision: 0, value: storageData.storageCount - storageData.storageTakeCount, name: '', suffix: '', hasBtn: false, btnText: ''},
            ],
        ]

        setList([
            {title: "用户概览", list: numList},
            {title: "订单概览", list: orderList},
            {title: "交易概览", list: transactionList},
            {title: "资金概览", list: priceList},
            {title: "存酒概览", list: wineStorageList},
        ])
    });

    // val 当前指定值, index 列表中的下标
    const getType = (val, index) => {
        // console.log(val, index);
    }

    const reload = () => {
        setSendModalVisible(true);
    }
    
    return (
        <>
            <PageHeader title="概览" />
            <PageToolbar>
                <ButtonGroup>
                    <Button  type="primary" onClick={reload}>
                        前往商城
                    </Button>
                </ButtonGroup>
                <ToolbarFilled />
            </PageToolbar>
            <PageContent>
                <Chart1 />
            </PageContent>
            {
                list.map((item, index) => {
                    return (
                        <StatisticsModel
                            listIndex={index}
                            key={index}
                            getType={getType}
                            dateChange={false}
                            title={item.title} 
                            numList={item.list}
                        />
                    )
                })
            }
            <Modal title="使用微信扫一扫,体验我存会员Club"
                visible={sendModalVisible}
                onOk={()=>setSendModalVisible(false)}
                onCancel={()=>setSendModalVisible(false)}
            > 
                <div style={{ display: "flex", justifyContent:'center' }}>
                    <img src="https://db.wocun9.com/qrcode.3fc810dc.jpg" alt=""/>
                </div>
            </Modal>
        </>
    );
};

export default Dashboard;