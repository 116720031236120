"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RxRepos = void 0;
var tslib_1 = require("tslib");
var connection_1 = require("./connection");
var RxRepos = /** @class */ (function () {
    function RxRepos(configName, config) {
        this.configName = configName;
        this.config = config;
    }
    RxRepos.prototype.getDatabase = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, connection_1.RxDbConnection.get(this.configName)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    RxRepos.prototype._getCollection = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var db;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDatabase()];
                    case 1:
                        db = _a.sent();
                        return [2 /*return*/, db.collection(this.config)];
                }
            });
        });
    };
    RxRepos.prototype.getCollection = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.collection_pormise) {
                    this.collection_pormise = this._getCollection();
                }
                return [2 /*return*/, this.collection_pormise];
            });
        });
    };
    RxRepos.prototype.get = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection, map;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [4 /*yield*/, collection.findByIds(id)];
                    case 2:
                        map = _a.sent();
                        return [2 /*return*/, map.get(id)];
                }
            });
        });
    };
    RxRepos.prototype.filter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [2 /*return*/, collection.find()];
                }
            });
        });
    };
    RxRepos.prototype.add = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [2 /*return*/, collection.insert(entity)];
                }
            });
        });
    };
    RxRepos.prototype.addOrUpdate = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [2 /*return*/, collection.atomicUpsert(entity)];
                }
            });
        });
    };
    RxRepos.prototype.bulkInsert = function (entitys) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [2 /*return*/, collection.bulkInsert(entitys)];
                }
            });
        });
    };
    RxRepos.prototype.remove = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var collection, map;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCollection()];
                    case 1:
                        collection = _a.sent();
                        return [4 /*yield*/, collection.findByIds(id)];
                    case 2:
                        map = _a.sent();
                        if (map[id]) {
                            map[id].remove();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return RxRepos;
}());
exports.RxRepos = RxRepos;
