import ImageMogr2 from '@jsmodules/image-mogr2';

const checkWebp = function () {
    try {
        return document.createElement("canvas").toDataURL("image/webp").indexOf("data:image/webp") === 0;
    } catch (err) {
        return false;
    }
};
const isWebP = checkWebp();

export function setupImageMogr2() {
    ImageMogr2.Creater.use((img2) => {
        if (isWebP) {
            img2.format("webp");
        }
        return img2;
    });
}
