"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMounted = void 0;
var react_1 = require("react");
function useIsMounted() {
    var isMounted = react_1.useRef(false);
    react_1.useEffect(function () {
        isMounted.current = true;
        return function () {
            isMounted.current = false;
        };
    }, []);
    return isMounted;
}
exports.useIsMounted = useIsMounted;
