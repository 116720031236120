import { Modal } from 'antd';
import { AxiosResponse } from 'axios';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import React, { useImperativeHandle } from 'react';
import { dialog } from 'react-dialog-context';

import { LoadingOutlined } from '@ant-design/icons';

import useAntdDialog from '../AntdDialogHost/useAntdDialog';
import { defaultResponseMessages } from './defaultResponseMessages';
import styles from './index.module.less';

export interface IModalLoaderProps {
    message;
}

type ResponseErrorMessage =
    | string
    | {
          title: string;
          message: string;
      };

type CodeErrorMessageFunc = (data?: any, response?: AxiosResponse) => ResponseErrorMessage;

type ModalLoaderRunOptions = {
    hideToast?: boolean;
    loading?: string;
    fail?: string;
    responseMessages?: {
        [key: string]: ResponseErrorMessage | CodeErrorMessageFunc;
    };
};

export const RequestLoader = (props: IModalLoaderProps, ref) => {
    const [dialogProps, dialogActions] = useAntdDialog();
    useImperativeHandle(ref, () => {
        return {
            hide() {
                dialogActions.close(true);
            },
        };
    });
    console.log(dialogProps);
    return (
        <Modal
            {...dialogProps}
            footer={null}
            width={200}
            closable={false}
            maskClosable={false}
            bodyStyle={{ textAlign: "center" }}
            transitionName=""
            className={styles.modalLoader}
            maskStyle={{ backgroundColor: "rgba(0,0,0,.15)" }}
        >
            <LoadingOutlined size={24} />
            <p style={{ marginTop: "20px", marginBottom: 0 }}>{props.message}</p>
        </Modal>
    );
};

const RequestLoaderRef = React.forwardRef(RequestLoader);

RequestLoader.loading = function (message) {
    const modalRef = React.createRef<any>();
    dialog.show(<RequestLoaderRef ref={modalRef} message={message} />);
    return () => {
        modalRef.current?.hide();
    };
};

RequestLoader.run = async (fn, options: ModalLoaderRunOptions = {}) => {
    let hide: any = null;
    if (!options.hideToast) {
        hide = RequestLoader.loading(options.loading || "处理中...");
    }
    try {
        const data = await fn();
        hide && hide();
        return data;
    } catch (ex) {
        let title = "";
        let message: any = "";

        const httpCode = ex.response?.status;
        if (httpCode) {
            switch (httpCode) {
                case 400:
                    message = "提交的数据不正确";
                    break;
                case 500:
                    message = "服务器错误,请稍后再试";
                    break;
                case 502:
                    message = "服务不可用,请稍后再试";
                    break;
                case 404:
                    message = "你访问的资源不存在";
                    break;
                default:
                    break;
            }
        }

        const responseErrorMsg = ex.response?.data?.message;
        const errCode = ex.response?.data?.code || "__c";
        const errType = ex.response?.data?.type || "__t";
        const errorTypeCode = errCode + errType;
        if (responseErrorMsg) {
            const respMessages = options.responseMessages || {};
            message =
                respMessages[errorTypeCode] ||
                respMessages[responseErrorMsg] ||
                defaultResponseMessages[errorTypeCode] ||
                defaultResponseMessages[responseErrorMsg] ||
                responseErrorMsg;
            if (isFunction(message)) {
                message = message(ex.response.data, ex.response);
            }
            if (isObject(message)) {
                title = message["title"];
                message = message["message"];
            }
        }
        const errorInfo = {
            status: httpCode || "error",
            title: title || ex.title || "错误",
            content: message || options.fail || ex.message || "操作失败",
        };
        if(httpCode!=400) Modal.error(errorInfo);
        hide && hide();
        return Promise.reject(errorInfo);
    }
};
export default RequestLoader;
